import React from "react";
import { Redirect } from "react-router-dom";

import Pageslogin from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Pagesregister from '../pages/Authentication/Register';
import ForgetPassword from '../pages/Authentication/ForgetPassword';
import LockScreen from "../pages/Authentication/pages-lock-screen";

//My Pages

import CustomerService from "../pages/customer support/index"
// import CustomerServiceReport from "../pages/customer support/report"
// import ComplainDetails from "../pages/customer support/details"
import MySupport from "../pages/customer support/mysupport"
import AddSupportPriority from "../pages/customer support/addpriority";
import AddSupportCategory from "../pages/customer support/addcategory";
import Profile from "../pages/profile/index"

const authProtectedRoutes = [

  //Customer Support

  { path: "/customerservice", component: CustomerService},
  // { path: "/customerservicereport", component: CustomerServiceReport},
  // { path: "/customercomplain", component: ComplainDetails},
  {path: "/mysupport", component: MySupport},
  {path: "/supportCategory", component: AddSupportCategory},
  {path: "/supportPriority", component: AddSupportPriority},
  { path: "/profile", component: Profile },

  { path: "/", exact: true, component: () => <Redirect to="/mysupport" /> }
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Pageslogin },
  { path: "/register", component: Pagesregister },
  { path: '/forget-password', component: ForgetPassword },
  { path: '/pages-lock-screen', component: LockScreen },
];

export { authProtectedRoutes, publicRoutes };
