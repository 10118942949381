import React, { Component } from 'react';
import {
    Col,
    Row,
    Button,
    Label,
    Input,
    ModalFooter,
    Modal,
    ModalBody,
    ModalHeader,
    FormGroup,
    Card,
    CardBody,
    Alert
} from "reactstrap";
//   import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Moment from "moment"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { MDBDataTable } from 'mdbreact';
import { RotatingLines } from  'react-loader-spinner'
import '../../App.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import datatable css
import "../Tables/datatables.scss";

// import url
import url from "../../helpers/apiUrl"

class MySupport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                
                { title: "My Support", link: "#" },
            ],
            modal_add: false,
            created_at_filter: '',
            ticket_name: '',
            ticket_description: '',
            priority_name: '',
            category_name: '',
            ticket_status: '',
            customer_remarks: '',
            technician_remarks: '',
            prevRemark: '',
            ticket: [],
            category: [],
            priority: [],
            users: [],
            modal_add_remark: false,
            modal_edit_status: false,
            id: '',
            user_id: '',
            category_id_filter: '',
            priority_id_filter: '',
            user_id_filter: '',
            ticket_id_filter: '',
            ticket_status_filter: '',
            from_date_filter: '',
            to_date_filter: '',
            created_at_filter: '',
            fromDate: '',
            toDate: '',
            remark: false,
            status: false,
            prev_ticket_status: '',
            DateAlert:false,
            isLoadingSave: false,
        }
        this.viewTickets = this.viewTickets.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.addRemark = this.addRemark.bind(this);
        this.tog_add_remark = this.tog_add_remark.bind(this);
        this.tog_edit_status = this.tog_edit_status.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.vieallSupportPriority = this.vieallSupportPriority.bind(this);
        this.viewsupportCategory = this.viewsupportCategory.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.tog_add = this.tog_add.bind(this);
        this.tog_add2 = this.tog_add2.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }

    componentDidMount() {
        this.props.setBreadcrumbItems("My Support", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var raw = JSON.stringify({
            "category": this.state.category_id_filter,
            "priority_id": this.state.priority_id_filter,
            "user_id": this.state.user_id_filter,
            "ticket_id": this.state.ticket_id_filter,
            "ticket_status": this.state.ticket_status_filter,
            "from_date": this.state.fromDate,
            "to_date": this.state.toDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/engineer/support/log`, requestOptions)
            .then(response => response.json())
            .then(data => {
                var array = []
                if (data.statusCode === "200") {

                    if(this.state.modal_add === true){
                        this.setState(prevState => ({
                            modal_add: false
                        }));
                    } 
                    for (let i = 0; i < data.body.length; i++) {
                        array.push({
                            id: data.body[i].id,
                            support_log_id: data.body[i].ticket_id,
                            ticket_name: data.body[i].ticket_name,
                            ticket_description: data.body[i].ticket_description,
                            user_id: data.body[i].user_id,
                            priority_name: data.body[i].priority_name,
                            category_name: data.body[i].category_name,
                            ticket_status: data.body[i].ticket_status,
                            customer_remarks: data.body[i].customer_remarks,
                            technician_remarks: data.body[i].technician_remarks,
                            created_at:  Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                            updated_at:  Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                            button: <div style={{display: "flex", justifyContent: "center"}}>
                                <Button type="button"
                                    onClick={
                                        () => this.tog_add_remark(data.body[i].id,data.body[i].technician_remarks)
                                    }
                                    style={
                                        { marginRight: 10 }
                                    }
                                    color="primary"
                                    className="waves-effect waves-light buttons-secondary">
                                    <i className="ti-pencil"></i>
                                </Button>
                                <Button type="button"
                                    onClick={
                                        () => this.tog_edit_status(data.body[i].id, data.body[i].ticket_status)
                                    }
                                    style={
                                        { marginRight: 10 }
                                    }
                                    color="success"
                                    className="waves-effect waves-light buttons-secondary">
                                    <i className="mdi mdi-progress-check"></i>
                                </Button>
                            </div>

                        })
                    }
                    this.setState({ ticket: array })
                    console.log(data)
                }
                else {
                    this.setState({
                        ticket: []
                    })
                }
            })
            .catch(error => console.log('error', error));
        this.getUsers();
        this.vieallSupportPriority();
        this.viewsupportCategory()
    }

    viewTickets() {
        this.setState({isLoadingSave : true})

        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom" || date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }

        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var raw = JSON.stringify({
            "category": this.state.category_id_filter,
            "priority_id": this.state.priority_id_filter,
            "user_id": this.state.user_id_filter,
            "ticket_id": this.state.ticket_id_filter,
            "ticket_status": this.state.ticket_status_filter,
            "from_date": fromDate,
            "to_date": toDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        console.log(requestOptions)

        fetch(`${url}/engineer/support/log`, requestOptions)
            .then(response => response.json())
            .then(data => {
                var array = []
                if (data.statusCode === "200") {
                    this.setState({isLoadingSave : false})

                    if(this.state.modal_add === true){
                        this.setState(prevState => ({
                            modal_add: false
                        }));
                    } 
                    for (let i = 0; i < data.body.length; i++) {
                        array.push({
                            id: data.body[i].id,
                            support_log_id: data.body[i].ticket_id,
                            ticket_name: data.body[i].ticket_name,
                            ticket_description: data.body[i].ticket_description,
                            user_id: data.body[i].user_id,
                            priority_name: data.body[i].priority_name,
                            category_name: data.body[i].category_name,
                            ticket_status: data.body[i].ticket_status,
                            customer_remarks: data.body[i].customer_remarks,
                            technician_remarks: data.body[i].technician_remarks,
                            created_at:  Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                            updated_at:  Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                            button: <div style={{display: "flex", justifyContent: "center"}}>
                                <Button type="button"
                                    onClick={
                                        () => this.tog_add_remark(data.body[i].id,data.body[i].technician_remarks)
                                    }
                                    style={
                                        { marginRight: 10 }
                                    }
                                    color="primary"
                                    className="waves-effect waves-light buttons-secondary">
                                    <i className="ti-pencil"></i>
                                </Button>
                                <Button type="button"
                                    onClick={
                                        () => this.tog_edit_status(data.body[i].id, data.body[i].ticket_status)
                                    }
                                    style={
                                        { marginRight: 10 }
                                    }
                                    color="success"
                                    className="waves-effect waves-light buttons-secondary">
                                    <i className="mdi mdi-progress-check"></i>
                                </Button>
                            </div>

                        })
                    }
                    this.setState({ ticket: array })
                    console.log(data)
                }
                else {
                    this.setState({
                        ticket: []
                    })
                }
            })
            .catch(error => console.log('error', error));
        
    }

    getUsers() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        var urll = `${url}/endusers/all`
        fetch(urll, {
            method: 'POST',
            headers: myHeaders,

        })
            .then((response) => response.json())
            .then(data => {
                var array = []
                for (let i = 0; i < data.body.length; i++) {
                    array.push({
                        sub: data.body[i].sub,
                        Username: data.body[i].Username,
                        given_name: data.body[i].given_name,
                        family_name: data.body[i].family_name,
                    })
                }
                this.setState({ users: array })

            })
    }

    viewsupportCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/category/support/active`, requestOptions).then(response => response.json()).then(data => {
            var array = []
            for (let i = 0; i < data.body.length; i++) {
                array.push({
                    id: data.body[i].id,
                    category_name: data.body[i].category_name,
                    category_description: data.body[i].category_description,
                    category_status: data.body[i].category_status,
                    created_at: data.body[i].created_at,
                    updated_at: data.body[i].updated_at,

                })
            }
            this.setState({ category: array })
            console.log(this.state.category)
        }).catch(error => console.log('error', error));

    }

    vieallSupportPriority() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/priority/support/active`, requestOptions).then(response => response.json()).then(data => {
            var array = []
            for (let i = 0; i < data.body.length; i++) {
                array.push({
                    id: data.body[i].id,
                    priority_name: data.body[i].priority_name,
                    priority_description: data.body[i].priority_description,
                    priority_status: data.body[i].priority_status,
                    created_at: data.body[i].created_at,
                    updated_at: data.body[i].updated_at,
                })
            }
            this.setState({ priority: array })
            console.log(this.state.priority)
        }).catch(error => console.log('error', error));
    }
    changeStatus() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ticket_status": this.state.ticket_status
        });
        console.log(raw, "raw remark")

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/engineer/support/log/status/` + this.state.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.viewTickets()
                this.tog_edit_status()
                this.setState({
                    //status: true,
                    ticket_status: ''
                })
                // setTimeout(() => {
                //     this.setState({
                //         status: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Support</h6>
                <p>Ticket status updated successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            })
            .catch(error => console.log('error', error));
    }

    addRemark() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "technician_remarks": this.state.technician_remarks
        });
        console.log("remark raw", raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/engineer/support/log/technician_remarks/` + this.state.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.statusCode === "200"){
                    console.log("remark", result)
                    
                    this.setState({
                        technician_remarks: ''
                    })
                    this.tog_add_remark()
                    this.viewTickets()
                    toast(<div className='toast-message-container'>
                    <h6>Support</h6>
                    <p>Remark added successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                 })
                    // setTimeout(() => {
                    //     this.setState({
                    //         remark: false
                    //     })
                    // }, 3000)
                }
                
            })
            .catch(error => console.log('error', error));
    }

    tog_add_remark(id,remark) {
        this.setState(prevState => ({
            id: id,
            modal_add_remark: !prevState.modal_add_remark,
            technician_remarks: remark,
            prevRemark: remark
        }));
    }
    tog_add() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add
        }));
    }

    tog_add2() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add,fromDate:'',toDate:'',created_at_filter:"",DateAlert:false

        }));
        setTimeout(() => {
            this.viewTickets()
        }, 1500);
    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }

    tog_edit_status(id, ticket_status) {
        this.setState(prevState => ({
            modal_edit_status: !prevState.modal_edit_status,
            id: id,
            ticket_status: ticket_status,
            prev_ticket_status: ticket_status
        }));
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2" })
            this.tog_add()
        }else{
            setTimeout(() => {
                this.viewTickets()
            }, 1500);
        }
    }

    clearFilter(){
        this.setState({
            category_id_filter: '',
            priority_id_filter: '',
            user_id_filter: '',
            ticket_id_filter: '',
            ticket_status_filter: '',
            technician_id_filter: '',
            from_date_filter: '',
            to_date_filter: '',
            created_at_filter: ''
        })
        setTimeout(() => {
            this.viewTickets()
        }, 100)
    }

    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Support</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }
    render() {
        const data = {
            columns: [
                {
                    label: 'Ticket ID',
                    field: 'support_log_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Ticket Name',
                    field: 'ticket_name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Ticket Description',
                    field: 'ticket_description',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'User Name',
                    field: 'user_id',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Priority',
                    field: 'priority_name',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Category',
                    field: 'category_name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Ticket Status',
                    field: 'ticket_status',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Customer Remarks',
                    field: 'customer_remarks',
                    sort: 'disabled',
                    width: 250
                },
                {
                    label: 'Technician Remarks',
                    field: 'technician_remarks',
                    sort: 'disabled',
                    width: 200
                },
                ,
                {
                    label: 'Created At',
                    field: 'created_at',
                    width: 200
                },
                ,
                {
                    label: 'Updated At',
                    field: 'updated_at',
                    width: 200
                },
                {
                    label: 'Action',
                    field: 'button',
                    sort: 'disabled',
                    width: 100
                }
            ],
            rows: this.state.ticket
        }

        return (
            <React.Fragment>
                <h3>My Tickets</h3>

                {/* Filters */}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <Row xs="12">
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Ticket ID</Label>
                                        <Col>
                                            <Input className="form-control" name="ticket_id_filter" type="text"
                                                value={
                                                    this.state.ticket_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }
                                                id="example-search-input" />
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Username</Label>
                                        <Col>
                                            <select className="form-control" name='user_id_filter'
                                                value={
                                                    this.state.user_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {
                                                    this.state.users.map((user) => (
                                                        <option value={user.Username}>{user.Username}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                    </Col>

                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Status</Label>
                                        <Col>
                                            <select className="form-control" name='ticket_status_filter'
                                                value={
                                                    this.state.ticket_status_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Open">Open</option>
                                                <option value="New">New</option>
                                                <option value="Running">Running</option>
                                                <option value="Completed">Completed</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-tel-input" className="col-form-label">Priority</Label>
                                        <Col>
                                            <select className="form-control" name='priority_id_filter'
                                                value={
                                                    this.state.priority_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {this.state.priority.map((cat) => (
                                                    <option value={cat.id}>{cat.priority_name}</option>
                                                ))}

                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-tel-input" className="col-form-label">Category</Label>
                                        <Col>
                                            <select className="form-control" name='category_id_filter'
                                                value={
                                                    this.state.category_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {this.state.category.map((cat) => (
                                                    <option value={cat.id}>{cat.category_name}</option>
                                                ))}

                                            </select>
                                        </Col>
                                    </Col>
                                    
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Created At</Label>
                                        <Col>
                                            <select className="form-control" name='created_at_filter'
                                                value={
                                                    this.state.created_at_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="0">Today</option>
                                                <option value="1">Yesterday</option>
                                                <option value="7">Last 7 days</option>
                                                <option value="30">Last 30 days</option>
                                                <option value="180">Last 6 months</option>
                                                <option value = "custom" onClick={this.tog_add}>{ this.state.created_at_filter === "custom" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                                                <option value = "custom2" hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>

                                            </select>
                                        </Col>
                                    </Col>
                                </Row>
                                <Col className="text-left" style={{marginTop: 10}}>
                                    <Button type="button"
                                        onClick={
                                            () => this.viewTickets()
                                        }
                                        color="warning"
                                        className="waves-effect waves-light buttons-primary">
                                        <i className='mdi mdi-filter-menu-outline'
                                            style={
                                                { marginRight: 10, fontSize: 15 }
                                            }
                                        ></i>
                                        Filter
                                    </Button>

                                    {
                                        this.state.created_at_filter === '' && this.state.category_id_filter === '' && this.state.priority_id_filter === '' && this.state.ticket_status_filter === '' && this.state.user_id_filter === '' && this.state.ticket_id_filter === '' ?
                                        <Button type="button" disabled
                                            style={{ marginLeft: 10 }}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light filter-disabled">

                                            Clear Filter
                                        </Button>:
                                        <Button type="button"
                                            style={{ marginLeft: 10 }}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light filter-buttons">

                                            Clear Filter
                                    </Button>
                                    }
                                    
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {this.state.status ? <Alert color="success">
                    <strong>Status updated successfully.</strong>
                </Alert> : null}
                {this.state.remark ? <Alert color="success">
                    <strong>Remark added successfully.</strong>
                </Alert> : null}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <MDBDataTable responsive bordered striped noBottomColumns
                                    data={data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Remarks */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_add_remark
                    }
                        toggle={
                            this.tog_add_remark
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader >
                            Add Remark
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup row className="formContainer">
                                <Label for="example-text-input" className="col-form-label ml-3">Remark</Label>
                                <Col sm="12">
                                    <Input className="mb-0 w-100" name="technician_remarks" type="text"
                                        value={
                                            this.state.technician_remarks
                                        }
                                        onChange
                                        ={this.changeHandler}
                                        id="example-text-input" />
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_add_remark
                                }>Close
                            </Button>
                            {
                                this.state.technician_remarks === "" || this.state.prevRemark === this.state.technician_remarks?
                                <Button type="button" color="primary"
                                disabled
                                onClick={
                                    () => this.addRemark()
                                }
                                className="waves-effect waves-light">Add Remark</Button>
                                : <Button type="button" color="primary"
                                onClick={
                                    () => this.addRemark()
                                }
                                className="waves-effect waves-light">Add Remark</Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>

                {/* Status */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_edit_status
                    }
                        toggle={
                            this.tog_edit_status
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader >
                            Change Status
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup row className="formContainer">
                                <Label for="example-password-input" className="col-form-label ml-3">Status</Label>
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='ticket_status'
                                        value={
                                            this.state.ticket_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled hidden>Select</option>
                                        <option value="Open">Open</option>
                                        <option value="New">New</option>
                                        <option value="Running">Running</option>
                                        <option value="Completed">Completed</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit_status
                                }>Close
                            </Button>
                            {
                                this.state.ticket_status === "" || this.state.ticket_status === this.state.prev_ticket_status ?
                                <Button type="button" color="primary"
                                    onClick={
                                        () => this.changeStatus()
                                    }
                                    disabled
                                    className="waves-effect waves-light">Change Status
                                </Button>
                                :<Button type="button" color="primary"
                                    onClick={
                                        () => this.changeStatus()
                                    }
                                    className="waves-effect waves-light">Change Status
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewTickets
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewTickets
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.viewTickets
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(MySupport);